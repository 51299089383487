/* Change this file to get your personal Portfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: false, // Change this to false if you want the good'ol cursor
  googleTrackingID: "",
};

//Home Page
const greeting = {
  title: "Hello.",
  title2: "Romel",
  logo_name: "Romel.sikdar()",
  nickname: "Aditya",
  full_name: "Romel Sikdar",
  subTitle:
    "Full Stack Developer, Cross Platform Tech Stack Enthusiast. Always learning.",
  resumeLink:
    "https://drive.google.com/file/d/1_axXcuDzVV99P_v1J33AdF57prldi88P/view?usp=sharing",
  mail: "mailto:romels500@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/RomelSikdar",
  linkedin: "https://www.linkedin.com/in/romelsikdar/",
  gmail: "romels500@gmail.com",
  gitlab: " ",
  facebook: "https://www.facebook.com/Figh7err",
  twitter: " ",
  instagram: "https://www.instagram.com/aditya.sikdar/",
};

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building Restful APIs for front end",
        "⚡ Developing front end user interface using ReactJS",
        "⚡ Backend development using NodeJS, ExpressJS and Koa",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "simple-icons:php",
          style: {
            color: "#7377AD",
          },
        },
        {
          skillName: "Dart",
          fontAwesomeClassname: "simple-icons:dart",
          style: {
            color: "#29B0EE",
          },
        },
        {
          skillName: "Go",
          fontAwesomeClassname: "simple-icons:go",
          style: {
            color: "#00A7D0",
          },
        },
        {
          skillName: "C#",
          fontAwesomeClassname: "simple-icons:csharp",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "C",
          fontAwesomeClassname: "simple-icons:c",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "C++",
          fontAwesomeClassname: "simple-icons:cplusplus",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:nodedotjs",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "ElectronJS",
          fontAwesomeClassname: "simple-icons:electron",
          style: {
            color: "#9FEAF9",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCB2B",
          },
        },
        {
          skillName: "GraphQL",
          fontAwesomeClassname: "simple-icons:graphql",
          style: {
            color: "#DE33A6",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#31648C",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Narula Institute of Technology",
      subtitle: "Masters in Computer Application",
      logo_path: "nit.png",
      alt_name: "NiT",
      duration: "2022 - 2024",
      descriptions: [
        "⚡ Studied Advanced Software Development Concepts, Data Management & Logic building",
        "⚡ Learn Advanced Topics in System Designing, System Architecture and Software Engineering",
        "⚡ Worked on projects on courses based on Web Technologies.",
      ],
      website_link: "https://www.nit.ac.in/",
    },
    {
      title: "Narula Institute of Technology",
      subtitle: "Bachelor in Computer Application",
      logo_path: "nit.png",
      alt_name: "NiT",
      duration: "2019 - 2022",
      descriptions: [
        "⚡ I have studied core subjects like Data Structures, DBMS, Networking, Security, etc.",
        "⚡ I have also completed various online courses for Web Development, BLockchain Development, etc.",
        "⚡ I have implemented several projects based on what I've learnt under my Computer Engineering course. ",
      ],
      website_link: "https://www.nit.ac.in/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Cisco CCNA 200-301",
      subtitle: "Cisco CCNA",
      logo_path: "ccna.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-b9e64aa4-6bd8-405e-9b7b-456c1f36b836/",
      alt_name: "CCNA",
      color_code: "#ffc475",
    },
    {
      title: "Social Network Analysis",
      subtitle: "UC Davis",
      logo_path: "ucdavis.png",
      certificate_link:
        "https://coursera.org/share/710919aea889fb0bd2dba7494e46a90c",
      alt_name: "SNA",
      color_code: "#ffc475",
    },
    {
      title: "Cloud Computing Basics",
      subtitle: "Learn Quest",
      logo_path: "lq.png",
      certificate_link:
        "https://coursera.org/share/0d16fa0324d65513b202484ff65b478a",
      alt_name: "Learn Quest",
      color_code: "#2AAFED",
    },

    {
      title: "Cyber Security & Ethical Hacking",
      subtitle: "ARDENT",
      logo_path: "ardent.png",
      alt_name: "ARDENT",
      color_code: "#E2405F",
    },
    {
      title: "Unreal Engine 4 Game Development",
      subtitle: "Game Development",
      logo_path: "ue.png",
      alt_name: "Game Development",
      color_code: "#C7C7C7",
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Home Automation And Visual Api Builder. Below are some of my projects. Note that not all of the mentioned projects are on GitHub yet.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "romel.png",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
};

const projects = {
  data: [
    {
      name: "EcoFlowJS",
      url: "https://github.com/EcoFlowJS/eco-flow",
      description: "A Node based GUI interface for deploying RestFull APIs.",
      languages: [
        {
          name: "Node.js",
          iconifyClass: "logos:nodejs",
        },
        {
          name: "KoaJS",
          iconifyClass: "simple-icons:koa",
        },
        {
          name: "TypeScript",
          iconifyClass: "devicon:typescript",
        },
        {
          name: "MongoDB",
          iconifyClass: "logos-mongodb",
        },
        {
          name: "Postgresql",
          iconifyClass: "logos:postgresql",
        },
        {
          name: "MySql",
          iconifyClass: "logos:mysql",
        },
        {
          name: "SQLite",
          iconifyClass: "logos:sqlite",
        },
        {
          name: "axios",
          iconifyClass: "logos:axios",
        },
        {
          name: "JWT",
          iconifyClass: "simple-icons:jsonwebtokens",
        },
        {
          name: "PassportJS",
          iconifyClass: "logos:passport",
        },
        {
          name: "Socket.io",
          iconifyClass: "logos:socket-io",
        },
        {
          name: "less",
          iconifyClass: "logos:less",
        },
        {
          name: "CLI",
          iconifyClass: "logos:clion",
        },
        {
          name: "Jotai",
          iconifyClass: "logos:jotai",
        },
      ],
    },
    {
      name: "Web Tv",
      url: "https://github.com/RomelSikdar/college",
      description: "An IMDB-like application.",
      languages: [
        {
          name: "React.js",
          iconifyClass: "logos-react",
        },
        {
          name: "TypeScript",
          iconifyClass: "devicon-typescript",
        },
        {
          name: "Node.js",
          iconifyClass: "devicon-nodejs",
        },
        {
          name: "Express.js",
          iconifyClass: "devicon-express",
        },
        {
          name: "MongoDB",
          iconifyClass: "logos-mongodb",
        },
      ],
    },
    {
      name: "Smart Home Development",
      url: "https://github.com/RomelSikdar/SmartHome",
      description:
        "Room electrical appliance controlling using IOT(Internet of Things).",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "MQTT",
          iconifyClass: "cbi-ipmi2mqtt",
        },
        {
          name: "Node-Red",
          iconifyClass: "cib-node-red",
        },
      ],
    },
    {
      name: "Meet Room",
      url: " ",
      description:
        "A meeting room where you can communicate with your team members with voice video support.",
      languages: [
        {
          name: "NodeJS",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "React.js",
          iconifyClass: "logos-react",
        },
        {
          name: "TypeScript",
          iconifyClass: "devicon-typescript",
        },
        {
          name: "Express.js",
          iconifyClass: "devicon:express",
        },
        {
          name: "Clerk.js",
          iconifyClass: "simple-icons:clerk",
        },
        {
          name: "stremio",
          iconifyClass: "arcticons:stremio",
        },
      ],
    },
    {
      name: "Inventory Management System",
      url: " ",
      description:
        "User is able to manage, customize and update stock of the items in the inventory or warehouse inventory.",
      languages: [
        {
          name: "Flutter",
          iconifyClass: "logos-flutter",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
    {
      name: "Payment and Delivery Tracking System",
      url: " ",
      description:
        "User is able to track and manage payment and delivery of the payment, also can preview the pending payments.",
      languages: [
        {
          name: "C#",
          iconifyClass: "devicon:csharp",
        },
        {
          name: "MySql",
          iconifyClass: "logos:mysql",
        },
      ],
    },
    {
      name: "Content Management System",
      url: " ",
      description: "A implementation of CMS system.",
      languages: [
        {
          name: "PHP",
          iconifyClass: "logos-php",
        },
        {
          name: "MySql",
          iconifyClass: "logos:mysql",
        },
        {
          name: "Javascript",
          iconifyClass: "devicon:javascript",
        },
        {
          name: "Bootstrap",
          iconifyClass: "skill-icons:bootstrap",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  projectsHeader,
  contactPageData,
  projects,
};
